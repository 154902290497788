/**
 * Syntax highlighting styles
 */

@media (prefers-color-scheme: dark) {
    @import "_style_base16.monokai.dark";
}

@media (prefers-color-scheme: light) {
    @import "_style_github";
}
