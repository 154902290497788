/* This file contains all the constants for colors and font styles */

$body-font: et-book, Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
/*
 * The tufte-css preferred sans font is Gill Sans, but this is not freely available.
 * We use Lato instead, which is royalty free.
 */
$sans-font:  Lato, LatoLatin, "Cabin", "Gill Sans", "Trebuchet MS", sans-serif;
$code-font: Consolas, "Liberation Mono", Menlo, "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
$text-color: #111;
$bg-color: #fffff8;
// $contrast-color: #111;
$border-color: #333333;
$link-style: color; // choices are 'color' or 'underline'. Default is color using $contrast-color set above


