
table {
    width: 100%;
    margin: 1em 0;
    border-collapse: collapse;
  
    th,
    td {
      padding: 0.5em 0.75em;
      text-align: left;
      border-bottom: 1px solid #ccc;
    }
  
    thead th {
      border-bottom: 2px solid #999;
      font-weight: bold;
    }
  
    tbody tr:last-of-type td {
      border-bottom: none;
    }
  }
  