/* Overrides and additional CSS functionality */

// .contrast { color: $contrast-color;}
.smaller { font-size: 80%;}
.larger { font-size: 2.8rem;}

.faded { color: rgba(17,17,17,0.5); }
@media (prefers-color-scheme: dark) {
    .faded { color: rgba(255, 255, 255, 0.5); }
}

/**
 * Make it so that top-level <p> don't need to be wrapped in <section>
 */
article {
    p, li, footer, table {
        width: 55%;
    }
}
footer {
    width: 55%;
}
// should still go wide on narrow screens
@media (max-width: 760px) {
    article {
        p, li, footer, table {
            width: 100%;
        }
    }
    footer { width: 100%; }
}


/* Back home link */
p.backarrow {
    font-size: 1.2rem;
    font-style: italic;
    margin-top: 3rem;
}


/* Styles for nav and headers */

/* Style for active menu links: bold and with surrounding angle brackets */
nav.group a.active { font-weight: bold; }
nav.group a.active:before { content:"\27e8"; }
nav.group a.active:after { content:"\27e9" ; }

header > nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header > nav a {
  font-size: 1.3rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  // color: $contrast-color;
  text-decoration: none;
  background: unset !important;
  text-shadow: unset !important;
  display: inline-block;
  float: left;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2rem;
  margin-top: 1.5rem;
  padding-right: 0;
  vertical-align: baseline;
}
header > nav li:last-child a {
  margin-right: inherit;
}
header > nav a img{
  height: 5rem;
  position: relative;
  max-width: 100%;
  top:-1.5rem;
}

header::after {
    display: block;
    content: '';
    clear: both;
}

.clear::after {
    display: block;
    content: '';
    clear: both;
}

// article doesn't need top padding due to nav and h1 margins
article {
  padding-top: 0em;
}


/* Footers */

ul.footer-links, .credits{
  list-style: none;
  text-align: center;
  margin: 0 auto;
}
ul.footer-links li{
  display: inline;
  padding: 0.5rem 0.25rem;
}
.credits{
  padding: 1rem 0rem;
}

/* End of styles for headers and footers */


// Full width page styling stuff

.full-width article {
    p, li, footer, table {
        width: 90%;
    }
}
.full-width footer {
    width: 90%;
}


li.listing hr{
  width:100%;
}
.listing, .listing h3
{
  display: inline-block;
  margin:0;
}
li.listing {
  margin:0;
  & p{
    width: 100%
  }
}


li.listing:last-of-type{
  border-bottom: none;
  margin-bottom: 1.4rem;
}
li.listing h3.new {
  text-transform: uppercase;
  font-style: normal;
}
hr.slender {
    border: 0;
    height: 1px;
    margin-top: 2.1rem;
    margin-bottom:2.1rem;
    background-color: black;
//    background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
//    background-image:    -moz-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
//    background-image:     -ms-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
//    background-image:      -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
}
// End of front listing page stuff


// Printing ands screen media queries

// Does not display a print-footer for screen display
@media screen{
  .print-footer{
    display: none;
  }
}

//printing stuff
@media print {

    * {
    -webkit-transition: none !important;
    transition: none !important;
    }
    *,
    *:before,
    *:after {
        background: transparent !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    @page {
        margin: 0.75in 0.5in 0.75in 0.5in;
        orphans:4; widows:2;
    }

    body {
        font-size:  12pt;

    }
    html body span.print-footer{
      font-size: 9pt;
      margin-top: 22.4pt;
      padding-top: 4pt;
      border-top: 1px solid #000;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 4;
        widows: 4;
    }
    article h2, article h2 h3, article h3, article h3 h4, article h4, article h4 h5 {
        page-break-after: avoid;
    }

    // Hide screen headers and footers
    body header, footer.page-footer, p.backarrow {
      display: none;
    }
}


// --------- Typography stuff -----------//
// added rational line height and margins ala http://webtypography.net/intro/

// h1 { font-weight: 400;
//     margin-top: 1.568rem;
//     margin-bottom: 1.568rem;
//     font-size: 2.5rem;
//     line-height: 0.784; }
//
// h2 { font-style: italic;
//     font-weight: 400;
//     margin-top: 1.866666666666667rem;
//     margin-bottom: 0;
//     font-size: 2.1rem;
//     line-height: 0.933333333333333; }
//
// h3 { font-style: italic;
//      font-weight: 400;
//      font-size: 1.8rem;
//      margin-top: 2.1777777777777778rem;
//      margin-bottom: 0;
//     line-height: 1.08888888888889; }
h1 { font-weight: 400;
     margin-top: 4rem;
     margin-bottom: 2rem;
     font-size: 3.2rem;
     line-height: 1; }

h1.header-title { font-weight: 400;
     margin-top: 4rem;
     margin-bottom: 2rem;
     font-size: 6rem;
     line-height: 1;
     a {
       background: unset;
       text-shadow: unset;
     }
}

h2 { font-style: italic;
     font-weight: 400;
     margin-top: 5.1rem;
     margin-bottom: 0;
     font-size: 2.2rem;
     line-height: 1; }

h2.header-subtitle { font-weight: 400;
     margin-top: 4rem;
     margin-bottom: 1.5rem;
     font-size: 3rem;
     font-style: italic;
     line-height: 1;
     }

h3 { font-style: italic;
     font-weight: 400;
     font-size: 1.7rem;
     margin-top: 2rem;
     margin-bottom: 0;
     line-height: 1; }

// ET style doesn't like more than 3 levels of headings


/* Reduce size of header-title on narrow phone screens */
@media screen and (max-width: 500px) {
  h1.header-title {
    font-size: 4rem;
  }
  h2.header-subtitle {
    font-size: 2.5rem;
  }
}

p.subtitle {
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    display: block;
    line-height: 1;
}

p.byline {
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 3rem;
    // font-size: 1.4rem;
    display: block;
    line-height: 1;
    color: rgba(0, 0, 0, 0.6)
}
@media (prefers-color-scheme: dark) {
    p.byline { color: rgba(255, 255, 255, 0.6); }
}

p.margin-maker {
             margin-top: 1rem;
             margin-bottom: 3.4rem;
              }

p, ol, ul { font-size: 1.4rem; }

p, li { line-height: 2rem;
        // margin-top: 1.4rem;
        padding-right: 2rem; //removed because,  why?
        vertical-align: baseline; }


.sans { font-family: $sans-font;
        letter-spacing: .03em; }


// removed .code 'class' since code is an actual html tag
// also added p code, p pre code and pre selector to account for Markdown parsing
// of triple backticks plus rationalized line-heights and margins
pre, pre code, p code, p pre code {
    font-family: $code-font;
    font-size: 1rem;
    line-height: 1.71428571;
    margin-top: 1.71428571rem;

    // override tufte-css and make code blocks full width
    width: inherit;
}


h1 code, h2 code, h3 code { font-size: 0.80em; } //left in for no real reason


/*
 * Fancy Blockquotes
 * Style the blockquotes with a fancy quote character at the beginning
 */
blockquote.fancy p:first-child {
  position: relative;
}
blockquote.fancy p:first-child::before {
  content: "“";
  display: block;
  position: absolute;
  font-size: 5rem;
  top: 0.9rem;
  left: -3.4rem;
}
/* Make all elements in a blockquote except the last line of a blockquote non-italic so it looks like an attribution line.
 * To avoid this, either add an empty <p></p> at the end, style the p with italic, or don't use the fancy blockquote.
 */
blockquote.fancy p {
  font-style: italic;
}
